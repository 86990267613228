import styled from "styled-components"

const StyledSwiper = styled.div`
  .swiper-container {
    overflow: visible;

    .swiper-pagination {
      bottom: -30px;

      .swiper-pagination-bullet {
        border: 2px solid white;
        opacity: 1;
        background-color: transparent;
      }

      .swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
`

export default StyledSwiper
