import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from "swiper"
import { graphql } from "gatsby"
import StyledSwiper from "../styles/StyledSwiper"
import parse from "html-react-parser"
import Img from "gatsby-image"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")
require("swiper/components/effect-fade/effect-fade.scss")

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay])

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-top: 66.6666%;
  position: relative;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
`

const StyledText = styled.div`
  max-width: 992px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: #e5e5e5;
    }
  }
`

export default function FokusPage({ data }) {
  const focusProjects = data.allWpFokus.nodes

  console.log(focusProjects)

  return (
    <StyledDiv>
      <Helmet>
        <title>Aktuell – Thierry Seiler Webseiten und Webapps, Zürich</title>
        <meta
          property="og:title"
          content="Aktuelle Projekte – Thierry Seiler Webseiten und Webapps, Zürich"
        />
        <meta property="og:description" content="Aktuelle Projekte" />
      </Helmet>

      <div className="container inner">
        {focusProjects.map(project => (
          <div className="row mb-30">
            <div className="col col-12 col-md-6 mb-9 mb-md-12">
              <StyledSwiper>
                <Swiper
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  loop
                  effect="fade"
                  autoplay={{ delay: 3000 }}
                >
                  {project.acf_fokus?.galerie?.map(photo => (
                    <SwiperSlide>
                      <ImageContainer>
                        <div className="inner">
                          <Img fluid={photo.localFile.childImageSharp.fluid} />
                        </div>
                      </ImageContainer>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </StyledSwiper>
            </div>
            <div className="col col-12 col-md-6">
              <StyledText className="mr-3 ">
                {/* <p>{project.date}</p> */}
                <h2 className="mb-3">{project.title}</h2>
                {parse(project.content)}
              </StyledText>
            </div>
          </div>
        ))}
      </div>
    </StyledDiv>
  )
}

export const pageQuery = graphql`
  query AllFokusData {
    allWpFokus(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        content
        title
        date(formatString: "DD. MMMM YYYY", locale: "de")
        acf_fokus {
          galerie {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`
